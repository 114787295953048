@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800,900);
@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800,900);
body, html{
  font-family: "Montserrat";
  background-image:url(/static/media/uucargo_background.732f39b1.png);
  background-position: center center;
  background-size: cover;
  background-repeat: inherit;
  height:100%
}

/* sign-up grid*/
.sign-up-container{
  display: grid;
  grid-template-columns: 700px auto ;
  grid-template-rows: 0.7fr auto;
  grid-template-areas:
    "sign-up-content0 sign-up-content0"
    "sign-up-content1 sign-up-content2";
  grid-gap: 0.5rem;
  -webkit-justify-content: center;
          justify-content: center;
}

#sign-up-content0{
  grid-area: sign-up-content0;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  display: inline-block;
 
}

#sign-up-content1{
  grid-area: sign-up-content1;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
 
}

#sign-up-content2{
  grid-area: sign-up-content2;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
}

:root {
    --main-radius: 5px;
    --main-padding: 5px;
  }


  
  .container {
    display: grid;
    grid-template-columns: 0.4fr 0.5fr ;
    grid-template-rows: 0.2fr 0.2fr ;
    grid-template-areas: 
    "content1 content2"
    "content3 content4"
    "content5 content6"
    "content7 content8"
    "content9 content10";
    grid-gap: 0.5rem;
    border-radius: 25px;
    background-color: white;
  }

  .creditContainer {
    display: grid;
    grid-template-columns: 0.7fr   ;
    grid-template-rows: 0.2fr  ;
    grid-template-areas: 
    "content 1"
    "content 2"
    "content 3"
    "content 4"
    "content 5";
    grid-gap: 0.5rem;
    border-radius: 25px;
    -webkit-justify-content: center;
            justify-content: center;
  }

  .socialContainer {
    display: grid;
    grid-template-columns: 0.7fr   ;
    grid-template-rows: 0.2fr;
    grid-template-areas: 
    "button1"
    "button2"
    "credentials1";
    grid-gap: 0.5rem;
    border-radius: 25px;
    -webkit-justify-content: center;
            justify-content: center;
    text-align: center;
  }

  .searchHeader{
    background-color: white;
    width:100%;
    min-width: 300px;
    height: 80px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
  }

  .MuiPaper-root{
    width:40%;
    min-width:200px;
    margin: 10px auto;
  }

  .MuiPaper-rounded{
    border-radius: 0%;
  }

  .search-setting-page{
    margin:0 auto;
    width:50%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .search-options{
    display:grid;
  }

  #shipmentInfo{
    -webkit-flex-grow: 1;
            flex-grow: 1;
    text-align: center;
  }

  #button1{
    color:green;
  }

  #button2{
    color: green;
  }

  #crendentials1{
    color:green;
  }



  #sidebar {
    grid-area: sidebar
  }

  #main {
    grid-area : main
  }


  .signUpButton {
    border-radius: 20px;

  }

  .textfield {
    border-radius: 25px;
    background-color: white;
  }
  
  #content1 {
    border-radius: 25px;
    background-color: white;
    
  }
  
  #content2 {
    border-radius: 25px;
    background-color: white;
    
  }

  #content3{
    border-radius: 25px;
    background-color: white;
    
  }
  
  #content4 {
    border-radius: 25px;
    background-color: white;
  }

  @media only screen and (max-width: 550px) {
    .creditContainer {
      grid-template-columns: 1fr ;
      grid-template-rows: 0.4fr 0.4fr 0.4fr 0.4fr ;
      grid-template-areas:
        "sidebar"
        "main";
     
    }
  }
  
  
  @media only screen and (max-width: 550px) {
    .container {
      grid-template-columns: 1fr ;
      grid-template-rows: 0.4fr 0.4fr 0.4fr 0.4fr ;
      grid-template-areas:
        "content1"
        "content2"
        "content3"
        "content4"
     
    }
    .signUpContainer {
      display: grid;
      grid-template-columns: 1fr ;
      grid-gap: 0.5rem;
      border-radius: 25px;
      width: 400px;
    }

  }


.AppBar {
  background-color: white;
}

.subtitle {
    font-size: 18pt;
    color: darkgreen;
    font-family: "Montserrat";
    font-weight: 600;
}

.bodytext {
    font-size: 16pt;
    color: seagreen;
    font-family: "Montserrat";
}

.navButton {
    border-color: green;
    outline: 2px solid green;
}

.userForm{
    border-radius: 20px;
    border: 2px solid black;
    padding: 20px;
    max-width: 50%;
}

.jumboStyle {
    color: "black";
    height: "100vh";
    font-family: "Montserrat";
    font-size: "1vw";
    letter-spacing: "2px";
    display: "flex";
    -webkit-align-items: "center";
            align-items: "center";
    -webkit-flex-direction: "column";
            flex-direction: "column";
    -webkit-justify-content: "center";
            justify-content: "center";
    text-align: "center";
    overflow: "hidden";
}


.loginBtn {
  box-sizing: border-box;
  position: relative;
  /* width: 13em;  - apply for fixed size */
  margin: 0.2em;
  padding: 0 15px 0 46px;
  border: none;
  text-align: left;
  line-height: 34px;
  white-space: nowrap;
  border-radius: 0.2em;
  font-size: 16px;
  color: #FFF;
}
.loginBtn:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 34px;
  height: 100%;
}
.loginBtn:focus {
  outline: none;
}
.loginBtn:active {
  box-shadow: inset 0 0 0 32px rgba(0,0,0,0.1);
}


/* Facebook */
.loginBtn--facebook {
  background-color: #4C69BA;
  background-image: linear-gradient(#4C69BA, #3B55A0);
  /*font-family: "Helvetica neue", Helvetica Neue, Helvetica, Arial, sans-serif;*/
  text-shadow: 0 -1px 0 #354C8C;
}
.loginBtn--facebook:before {
  border-right: #364e92 1px solid;
  background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_facebook.png') 6px 6px no-repeat;
}
.loginBtn--facebook:hover,
.loginBtn--facebook:focus {
  background-color: #5B7BD5;
  background-image: linear-gradient(#5B7BD5, #4864B1);
}

/* google */
.google-button {
  height: 40px;
  border-width: 0;
  background: white;
  color: #737373;
  border-radius: 5px;
  white-space: nowrap;
  box-shadow: 1px 1px 0px 1px rgba(0,0,0,0.05);
  transition-property: background-color, box-shadow;
  transition-duration: 150ms;
  transition-timing-function: ease-in-out;
  padding: 0;
}
    
.google-button__icon {
  display: inline-block;
  vertical-align: middle;
  margin: 8px 0 8px 8px;
  width: 18px;
  height: 18px;
  box-sizing: border-box;
}

.google-button__icon--plus {
  width: 27px;
}

.google-button__text {
  display: inline-block;
  vertical-align: middle;
  padding: 0 24px;
  font-size: 14px;
  font-weight: bold;
  font-family: 'Roboto',arial,sans-serif;
}

button ~ button {
  margin-left: 20px;
}




body, html{
  font-family: "Montserrat";
  background-image:url(/static/media/uucargo_background.732f39b1.png)
}

a:hover {
    cursor:pointer;
   }

:root {
    --main-radius: 5px;
    --main-padding: 5px;
  }
  
  .quote-container {
    display: grid;
    grid-template-columns: 500px auto ;
    grid-template-rows: 0.6r 0.6fr 0.6fr 0.6fr 0.6fr;
    grid-template-areas: 
    "quote-content1 quote-content2"
    "quote-content3 quote-content2"
    "quote-content4 quote-content2"
    "quote-content5 quote-content2"
    "quote-content6 quote-content8"
    "quote-content7 quote-content8";
    grid-gap: 0.5rem;
    border-radius: 25px;
    -webkit-justify-content: center;
            justify-content: center;
    
  }

  @media only screen and (max-width: 550px) {
    .quote-container {
      grid-template-columns: 1fr ;
      grid-template-rows: 0.4fr auto ;
      grid-template-areas:
        "quote-content1"
        "quote-content2"
        "quote-content3"
        "quote-content4"
        "quote-content5"
        "quote-content6"
        "quote-content7"
        "quote-content8"
    }
  }

  #blank {
      grid-area: blank;
  }

  #side {
      grid-area: side;
  }
  
  #quote-content1 {
    border-radius: 25px;
    grid-area: quote-content1;
    color:orange;
    
  }
  
  #quote-content2 {
    border-radius: 25px;
    grid-area: quote-content2;
    text-align: center;
    
  }

  #quote-content3{
    grid-area: quote-content3;
    border-radius: 25px;
    
  }
  
  #quote-content4 {
    border-radius: 25px;
    grid-area: quote-content4;
  }

  #quote-content5 {
    grid-area: quote-content5;
    border-radius: 25px;
  }

  #quote-contentt6 {
    grid-area: quote-content6;
    border-radius: 25px;
  }

  #quote-content7 {
    grid-area: quote-content7;
    border-radius: 25px;
  }

  #quote-content8 {
    grid-area: quote-content8;
    border-radius: 25px;
    text-align: center;
  }

  #iconbuttonarrow {
      background-color:rgb(30, 100, 30);
  }

  #button1{
    color:green;
  }

  #button2{
    color: green;
  }

  #crendentials1{
    color:green;
  }

  #sidebar {
    grid-area: sidebar
  }

  #main {
    grid-area : main
  }

  .signUpContainer {
    display: grid;
    grid-template-columns: 1fr ;
    grid-gap: 0.5rem;
    border-radius: 25px;
    width: 400px;
  }

  .signUpButton {
    border-radius: 20px;

  }

  .textfield {
    border-radius: 25px;
    background-color: white;
  }

.AppBar {
  margin-top: 0px;
  background-color: white;
}

.title {
    font-size: 18pt;
    color: orange;
    font-family: "Montserrat";
    font-weight: 600;
}

.subtitle {
    font-size: 18pt;
    color: darkgreen;
    font-family: "Montserrat";
    font-weight: 600;
}


.bodytext {
    font-size: 16pt;
    color: seagreen;
    font-family: "Montserrat";
}

.navButton {
    border-color: green;
    outline: 2px solid green;
}

.userForm{
    border-radius: 20px;
    border: 2px solid black;
    padding: 20px;
    max-width: 50%;
}

.jumboStyle {
    color: "black";
    height: "100vh";
    font-family: "Montserrat";
    font-size: "1vw";
    letter-spacing: "2px";
    display: "flex";
    -webkit-align-items: "center";
            align-items: "center";
    -webkit-flex-direction: "column";
            flex-direction: "column";
    -webkit-justify-content: "center";
            justify-content: "center";
    text-align: "center";
    overflow: "hidden";
}


button ~ button {
  margin-left: 20px;
}



